@font-face {
  font-family: 'montserrat-regular';
  src: url(../src/assets/fonts/Montserrat-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-medium';
  src: url(../src/assets/fonts/Montserrat-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-bold';
  src: url(../src/assets/fonts/Montserrat-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'montserrat-black';
  src: url(../src/assets/fonts/Montserrat-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-regular';
  src: url(../src/assets/fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-medium';
  src: url(../src/assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-bold';
  src: url(../src/assets/fonts/Roboto-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
